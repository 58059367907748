
import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
  props: {
    src: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const video = ref<any>(null)
    onMounted( () => {
      if(props.autoplay) {
        video.value.setAttribute('autoplay', 'autoplay')
      }
    })
    return {
      video
    }
  }
})
